@import url('https://fonts.googleapis.com/css?family=Poppins:regular&subset=latin,latin-ext');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* For Firefox Browser */
    .scrollbar {
        width: thin;
        scrollbar-color: #e2e2e2 #f3f4f6;
    }

    /* For Chrome, EDGE, Opera, Others */
    .scrollbar::-webkit-scrollbar {
        width: thin;
        height: 20px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f3f4f6;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e2e2e2;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #e9e9e9;
    }
}